import React from "react";
import { ChatCitationButton } from "../ChatCitationButton";
import type { MessageCitation } from "../types";

interface ChatDocumentCitationItemProps {
  citation: MessageCitation;
  onOpen?: (citation: MessageCitation) => void;
  selectedCitationId?: string | null;
}

export const ChatDocumentCitationItem = (
  props: ChatDocumentCitationItemProps,
) => {
  const { citation, onOpen, selectedCitationId } = props;
  const isSelected = selectedCitationId === citation.id;

  return (
    <ChatCitationButton
      citationName={citation.name}
      isSelected={isSelected}
      onPress={() => onOpen?.(citation)}
    />
  );
};
