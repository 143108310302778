import type { SetterConfig, Stylesheet } from "entities/AppTheming";
import memoizeOne from "memoize-one";
import { Elevations } from "modules/ui-builder/ui/wds/constants";
import { ContainerComponent } from "modules/ui-builder/ui/wds/Container";
import React, { type ReactNode } from "react";
import type {
  AnvilConfig,
  AutocompletionDefinitions,
  WidgetBaseConfiguration,
  WidgetDefaultProps,
} from "WidgetProvider/constants";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { ContainerWidgetProps } from "widgets/ContainerWidget/widget";

import type { ActionData } from "ce/reducers/entityReducers/actionsReducer";
import { getCurrentEnvironmentId } from "ee/selectors/environmentSelectors";
import { getIsViewMode } from "selectors/editorSelectors";
import store from "store";
import {
  createThread,
  fetchMessages,
  fetchThreads,
  sendMessage,
  type AiAssistantStreamLine,
} from "../api";
import { AIChat } from "../component";
import { type Message, type Thread } from "../component/types";
import { StreamLineParser } from "../lib/StreamLineParser";
import { StreamReader } from "../lib/StreamReader";
import {
  anvilConfig,
  autocompleteConfig,
  defaultsConfig,
  metaConfig,
  methodsConfig,
  propertyPaneContent,
  propertyPaneStyle,
} from "./config";
import * as MessageMapper from "./mappers/messageMapper";
import * as MessageCitationMapper from "./mappers/messageCitationMapper";

export interface WDSAIChatWidgetProps
  extends ContainerWidgetProps<WidgetProps> {
  threadId: string;
}

class WDSAIChatWidget extends BaseWidget<WDSAIChatWidgetProps, WidgetState> {
  static type = "WDS_AI_CHAT_WIDGET";

  static getConfig(): WidgetBaseConfiguration {
    return metaConfig;
  }

  static getDefaults(): WidgetDefaultProps {
    return defaultsConfig;
  }

  static getPropertyPaneConfig() {
    return [];
  }
  static getPropertyPaneContentConfig() {
    return propertyPaneContent;
  }

  static getPropertyPaneStyleConfig() {
    return propertyPaneStyle;
  }

  static getMethods() {
    return methodsConfig;
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return autocompleteConfig;
  }

  static getSetterConfig(): SetterConfig | null {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
      },
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, unknown> {
    return {
      thread: undefined,
      messages: [],
      prompt: "",
      isWaitingForResponse: false,
      isThreadLoading: false,
    };
  }

  static getAnvilConfig(): AnvilConfig | null {
    return anvilConfig;
  }

  static getStylesheetConfig(): Stylesheet {
    return {};
  }

  componentDidMount(): void {
    this.getThreads();
  }

  getThreads = async () => {
    this.props.updateWidgetMetaProperty("isThreadLoading", true);

    try {
      const threads = await fetchThreads({
        queryId: this.getQueryId(),
        widgetId: this.props.widgetId,
      });

      this.handleGetThreadsComplete(threads);
    } catch (error) {
      // TODO: Handle error
    } finally {
      this.props.updateWidgetMetaProperty("isThreadLoading", false);
    }
  };

  getInitialAssistantMessage = memoizeOne(
    (initialAssistantMessage, initialAssistantSuggestions): Message[] => {
      return [
        {
          id: Math.random().toString(),
          content: initialAssistantMessage || "",
          role: "assistant",
          promptSuggestions: initialAssistantSuggestions || [],
          citations: [],
        },
      ];
    },
  );

  handleGetThreadsComplete = (threads: Thread[]) => {
    if (threads.length === 0) {
      this.props.updateWidgetMetaProperty(
        "messages",
        this.getInitialAssistantMessage(
          this.props.initialAssistantMessage,
          this.props.initialAssistantSuggestions,
        ),
      );

      return;
    }

    // BE send the latest updated thread at the top of the array
    this.props.updateWidgetMetaProperty("threadId", threads[0].id);

    this.getMessages();
  };

  getMessages = async () => {
    this.props.updateWidgetMetaProperty("isThreadLoading", true);

    try {
      const messages = await fetchMessages({
        threadId: this.props.threadId,
        queryId: this.getQueryId(),
        widgetId: this.props.widgetId,
      });

      this.props.updateWidgetMetaProperty("isThreadLoading", false);
      this.props.updateWidgetMetaProperty("messages", [
        ...this.getInitialAssistantMessage(
          this.props.initialAssistantMessage,
          this.props.initialAssistantSuggestions,
        ),
        ...messages,
      ]);
    } catch (error) {
      // TODO: Handle error.
    }
  };

  appendMessage = (message: Message) => {
    this.props.updateWidgetMetaProperty("messages", [
      ...this.props.messages,
      message,
    ]);
  };

  handleMessageSubmit = async () => {
    const prompt = this.props.prompt;

    this.appendMessage({
      createdAt: new Date(),
      id: Math.random().toString(),
      content: prompt,
      role: "user",
      citations: [],
    });
    this.props.updateWidgetMetaProperty("isWaitingForResponse", true);
    this.props.updateWidgetMetaProperty("prompt", "");

    const state = store.getState();
    const activeEnv = getCurrentEnvironmentId(state);
    const queryId = this.getQueryId();
    const viewMode = getIsViewMode(state);

    // TODO: Handle this case
    if (!queryId) {
      this.props.updateWidgetMetaProperty("isWaitingForResponse", false);

      return;
    }

    try {
      const response = await sendMessage({
        threadId: this.props.threadId,
        widgetId: this.props.widgetId,
        message: prompt,
        activeEnv,
        queryId,
        viewMode,
      });

      await this.updateWidgetFromStream(response);
    } catch (error) {
      this.appendMessage({
        id: Math.random().toString(),
        content: "An error occurred while processing the message",
        role: "assistant",
        citations: [],
      });
    } finally {
      this.props.updateWidgetMetaProperty("isWaitingForResponse", false);
    }
  };

  updateWidgetFromStream = async (response: Response) => {
    const reader = response.body?.getReader();

    if (!reader) return;

    const streamReader = new StreamReader(reader);
    const streamLineParser = new StreamLineParser<AiAssistantStreamLine>();

    for await (const lines of streamReader.read()) {
      const parsedLine = streamLineParser.parse(lines);

      if (!parsedLine) continue;

      switch (parsedLine.event) {
        case "thread.run.created":
          this.props.updateWidgetMetaProperty(
            "threadId",
            parsedLine.data.threadId,
          );
          break;
        case "thread.message.created":
          this.appendMessage(
            MessageMapper.fromDto({
              ...parsedLine.data,
              message: parsedLine.data.delta.content,
            }),
          );
          break;
        case "thread.message.delta": {
          const lastMessage =
            this.props.messages[this.props.messages.length - 1];

          this.props.updateWidgetMetaProperty("messages", [
            ...this.props.messages.slice(0, -1),
            {
              ...lastMessage,
              content:
                lastMessage.content +
                parsedLine.data.delta.content[0].text.value,
            },
          ]);
          break;
        }
        case "citations.content": {
          const lastMessage =
            this.props.messages[this.props.messages.length - 1];

          this.props.updateWidgetMetaProperty("messages", [
            ...this.props.messages.slice(0, -1),
            {
              ...lastMessage,
              citations:
                parsedLine.data.citations
                  .map(MessageCitationMapper.fromDto)
                  .filter((citation) => citation !== null) || [],
            },
          ]);
          break;
        }
        case "citations.ref": {
          const lastMessage =
            this.props.messages[this.props.messages.length - 1];

          this.props.updateWidgetMetaProperty("messages", [
            ...this.props.messages.slice(0, -1),
            {
              ...lastMessage,
              content: lastMessage.content + parsedLine.data.refs,
            },
          ]);
          break;
        }
      }
    }
  };

  handlePromptChange = (prompt: string) => {
    this.props.updateWidgetMetaProperty("prompt", prompt);
  };

  handleApplyAssistantSuggestion = (suggestion: string) => {
    this.props.updateWidgetMetaProperty("prompt", suggestion);
  };

  onDeleteThread = async () => {
    if (!this.props.threadId) {
      this.props.updateWidgetMetaProperty("messages", [
        ...this.getInitialAssistantMessage(
          this.props.initialAssistantMessage,
          this.props.initialAssistantSuggestions,
        ),
      ]);

      return;
    }

    this.props.updateWidgetMetaProperty("isThreadLoading", true);

    try {
      // Instead of deleting the thread, we create a new one so that we can continue using the previous one
      await createThread({
        widgetId: this.props.widgetId,
        queryId: this.getQueryId(),
      });

      this.props.updateWidgetMetaProperty("threadId", null);
      this.props.updateWidgetMetaProperty("messages", [
        ...this.getInitialAssistantMessage(
          this.props.initialAssistantMessage,
          this.props.initialAssistantSuggestions,
        ),
      ]);
    } catch (error) {
      // TODO: Handle error
    } finally {
      this.props.updateWidgetMetaProperty("isThreadLoading", false);
    }
  };

  getQueryId(): string {
    const state = store.getState();
    const queryName = this.props.queryRun;

    const query: ActionData = state.entities.actions.find(
      (action: ActionData) => action.config.name === queryName,
    );

    if (!query) {
      throw new Error("Query not found");
    }

    return query.config.id;
  }

  getWidgetView(): ReactNode {
    return (
      <ContainerComponent
        elevatedBackground
        elevation={Elevations.CARD_ELEVATION}
        noPadding
        widgetId={this.props.widgetId}
      >
        <AIChat
          chatDescription={this.props.chatDescription}
          chatTitle={this.props.assistantName}
          isThreadLoading={this.props.isThreadLoading}
          isWaitingForResponse={this.props.isWaitingForResponse}
          onApplyAssistantSuggestion={this.handleApplyAssistantSuggestion}
          onDeleteThread={this.onDeleteThread}
          onPromptChange={this.handlePromptChange}
          onSubmit={this.handleMessageSubmit}
          prompt={this.props.prompt}
          promptInputPlaceholder={this.props.promptInputPlaceholder}
          queryId={this.getQueryId()}
          size={this.props.chatHeightSize}
          thread={this.props.messages}
          threadId={this.props.threadId}
        />
      </ContainerComponent>
    );
  }
}

export default WDSAIChatWidget;
