import type { Message } from "../../component/types";
import type { MessageDto } from "../dtos/messageDto";
import * as MessageCitationMapper from "./messageCitationMapper";

export const fromDto = (message: MessageDto): Message => {
  const citations =
    message.citations
      ?.map(MessageCitationMapper.fromDto)
      .filter((citation) => citation !== null) || [];

  return {
    createdAt: message.createdAt,
    id: message.id,
    content: message.message[0].text.value,
    role: message.role,
    citations,
  };
};
