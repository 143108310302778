import React, { useEffect, useMemo } from "react";
import { useSidebar, Spinner, Text, Button } from "@appsmith/wds";

import styles from "./styles.module.css";
import type { CitationDetails } from "./types";
import { PdfDocumentViewer } from "./documentViewers/PdfDocumentViewer";
import { TextDocumentViewer } from "./documentViewers/TextDocumentViewer";
import { MarkdownDocumentViewer } from "./documentViewers/MarkdownDocumentViewer";
import { assertIsBlob, assertIsString } from "../helpers";

interface ChatDocumentViewerProps {
  isAnimating: boolean;
  citation: CitationDetails | null;
  document?: Blob | string | null;
  isLoading: boolean;
  hasCitationError: boolean;
  hasDocumentError: boolean;
  selectedCitationId?: string | null;
  retryCitation: () => void;
  retryDocument: () => void;
}

export const ChatDocumentViewer = (props: ChatDocumentViewerProps) => {
  const {
    citation,
    document,
    hasCitationError,
    hasDocumentError,
    isAnimating,
    isLoading,
    retryCitation,
    retryDocument,
    selectedCitationId,
  } = props;
  const { setState } = useSidebar();

  useEffect(
    function handleNewCitation() {
      if (selectedCitationId) {
        setState("expanded");
      }
    },
    [selectedCitationId],
  );

  const content = useMemo(() => {
    if (!citation || !document || isAnimating) return null;

    switch (citation.integrationType) {
      case "PDF":
        assertIsBlob(document);

        return (
          <PdfDocumentViewer
            citation={citation}
            document={document}
            hasError={hasDocumentError}
            isResizing={isAnimating}
          />
        );
      case "TEXT":
        assertIsString(document);

        return (
          <TextDocumentViewer
            citation={citation}
            document={document}
            hasError={hasDocumentError}
          />
        );
      case "MD":
        assertIsString(document);

        return (
          <MarkdownDocumentViewer
            citation={citation}
            document={document}
            hasError={hasDocumentError}
          />
        );
      default:
        return null;
    }
  }, [document, citation, isAnimating, hasDocumentError]);

  return (
    <div className={styles.documentViewer}>
      {isLoading && (
        <div className={styles.documentViewerSpinner}>
          <Spinner />
          <Text>Loading the document...</Text>
        </div>
      )}
      {(hasCitationError || hasDocumentError) && !isLoading && (
        <div className={styles.documentViewerError}>
          <Text>Can&apos;t load the document...</Text>
          <Button
            color="neutral"
            onPress={hasCitationError ? retryCitation : retryDocument}
            size="small"
            variant="subtle"
          >
            Retry
          </Button>
        </div>
      )}
      {!isLoading && !hasCitationError && !hasDocumentError && content}
    </div>
  );
};
